<template>
  <div>
    <Modal v-model="showModal" :width="1000" footer-hide>
      <Row>
          <i-col flex="80px">
            站点：
          </i-col>
          <i-col flex="auto">
            喜马拉雅
          </i-col>
      </Row>
      <Row class="m-t-10 m-b-20">
          <i-col flex="80px" class="p-t-5">
            可用产品：
          </i-col>
          <i-col flex="auto">
            <div v-for="product in productList" :key="product.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProduct(product.id)"
                :class="checkProducts.includes(product.id)?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.name}}</div>
          </i-col>
      </Row>

      <Table stripe  :columns="columns"  :data="tableData" size="small"></Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator  @on-change="handleChangePage" ></Page>
      </div>
    </Modal>

    <!-- 显示资源详情 -->
    <Modal v-model="showDetailsModal" width="1000" footer-hide :styles="{top: '40px'}">
      <resourceDetail v-if="showDetailsModal" :resourceId="chooseResourceId" :categoryId="categoryId" :isManage="false"/>
    </Modal>
  </div>
</template>

<script>
import resourceDetail from '../bottomComponents/Detail'

import { getAllProduct } from '@/api/product/product'
import { getResourcePage } from '@/api/product/productresource'

export default {
  components: {
    resourceDetail
  },
  data () {
    return {
      showModal: false,
      productList: [],
      checkProducts: [],
      query: {
        assetIds: '',
        stationId: null,
        stationType: null,
        productIds: null,
        resourcetypeIds: '',
        productSkuId: null,
        position: null,
        soldStatus: [],
        orderTypes: [],
        stationIds: '',
        enabled: 1,

        schedules: JSON.stringify([{ startDate: '2023-05-26', endDate: '2023-06-26' }]),
        keyword: '',
        pageNumber: 1,
        pageSize: 10
      },

      columns: [
        { title: '资源编号', width: 150, key: 'code' },
        { title: '资源等级', key: 'stationLevelName' },
        { title: '所属站台', key: 'stationName' },
        { title: '所属楼层', width: 90, key: 'floor' },
        { title: '位置', key: 'positionName' },
        {
          title: '出口',
          render: (h, data) => {
            return h('span', data.row.exit === '' ? '-' : data.row.exit)
          }
        },
        { title: '类型', key: 'resourcetypeName' },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  // 主题媒体和常规取值不一样
                  this.chooseResourceId = params.row.resourceId
                  this.showDetailsModal = true
                }
              }
            }, '详情')
          }
        }
      ],
      tableData: [],
      total: 0,

      showDetailsModal: false,
      chooseResourceId: 0 // 当前选中的资源ID
    }
  },
  methods: {
    showStationResource (stationId) {
      this.showModal = true

      this.checkProducts = JSON.parse(this.searchCondition.productIds)
      this.query.assetIds = this.searchCondition.assetIds
      this.query.stationId = stationId
      this.query.productIds = this.searchCondition.productIds

      this.initPageData()
      this.loadResources()
    },
    initPageData () {
      const data = {
        assetIds: this.searchCondition.assetIds,
        categoryId: this.categoryId
      }

      getAllProduct(data).then(res => {
        if (res !== null && res.length > 0) {
          this.productList = res
        } else {
          this.productList = []
        }
      })
    },
    loadResources () {
      getResourcePage(this.query).then(res => {
        if (res) {
          this.tableData = res.list.map(x => x.productResourceBean)
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber === 0 ? 1 : res.pageNumber
        } else {
          this.tableData = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleChangePage (page) {
      this.query.pageNumber = page
      this.loadResources()
    },
    changeCheckedProduct (productId) {
      if (this.checkProducts.includes(productId)) {
        if (this.checkProducts.length === 1) {
          return
        }

        const index = this.checkProducts.indexOf(productId)
        this.checkProducts.splice(index, 1)
      } else {
        this.checkProducts.push(productId)
      }

      this.query.pageNumber = 1
      this.query.productIds = JSON.stringify(this.checkProducts)
      this.loadResources()
    }
  },
  computed: {
    searchCondition () {
      return this.$store.state.stock.searchCondition
    },
    categoryId () {
      return this.$store.state.stock.categoryId
    }
  }
}
</script>
